.App {
  text-align: center;
  width: 100%;
  box-sizing: border-box !important;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */

  .scroll {
    -ms-overflow-style: none; /* 인터넷 익스플로러 */
    scrollbar-width: none; /* 파이어폭스 */
  }

  ::-webkit-scrollbar {
    display: none;
  }
}
